import { IFoundationData } from "./../Interface/IFoundationData.interface";
import { IDynamicObject } from "xa-generics";

export const FoundationData: IDynamicObject<IFoundationData> = {
    "1": {
        title: "found-el1-title",
        topTexts: ["found-el1-text1", "found-el1-text2", "found-el1-text3", "found-el1-text4"],
        links: [
            {
                linkHeader: "found-el1-link1-head",
                link: "found-el1-link1-link"
            },
            {
                linkHeader: "found-el1-link2-head",
                link: "found-el1-link2-link"
            }
        ],
        tableData: [
            { title: "found-el1-table-title1", desc: "found-el1-table-text1" },
            {
                title: "found-el1-table-title2",
                desc: ["found-el1-table-text2-1", "found-el1-table-text2-2"]
            },
            { title: "found-el1-table-title3", desc: "found-el1-table-text3" },
            { title: "found-el1-table-title4", desc: "found-el1-table-text4" },
            { title: "found-el1-table-title5", desc: "found-el1-table-text5" },
            { title: "found-el1-table-title6", desc: "found-el1-table-text6" },
            {
                title: "found-el1-table-title7",
                desc: [
                    "found-el1-table-text7-1",
                    "found-el1-table-text7-2",
                    "found-el1-table-text7-3",
                    "found-el1-table-text7-4"
                ]
            },
            { title: "found-el1-table-title8", desc: "found-el1-table-text8" },
            { title: "found-el1-table-title9", desc: "found-el1-table-text9" },
            { title: "found-el1-table-title10", desc: "found-el1-table-text10" },
            { title: "found-el1-table-title11", desc: "found-el1-table-text11" },
            { title: "found-el1-table-title12", desc: "found-el1-table-text12" },
            { title: "found-el1-table-title13", desc: "found-el1-table-text13" }
        ]
    },
    "2": {
        title: "found-el2-title",
        topTexts: ["found-el2-text1", "found-el2-text2", "found-el2-text3"],
        links: [],
        tableData: [
            { title: "found-el1-table-title1", desc: "found-el2-table-text1" },
            {
                title: "found-el1-table-title2",
                desc: ["found-el2-table-text2-1", "found-el2-table-text2-2"]
            },
            { title: "found-el1-table-title3", desc: "found-el2-table-text3" },
            { title: "found-el1-table-title4", desc: "found-el2-table-text4" },
            { title: "found-el1-table-title5", desc: "found-el2-table-text5" },
            { title: "found-el1-table-title6", desc: "found-el2-table-text6" },
            {
                title: "found-el1-table-title7",
                desc: [
                    "found-el2-table-text7-1",
                    "found-el2-table-text7-2",
                    "found-el2-table-text7-3",
                    "found-el2-table-text7-4"
                ]
            },
            { title: "found-el1-table-title8", desc: "found-el2-table-text8" },
            { title: "found-el1-table-title9", desc: "found-el2-table-text9" },
            { title: "found-el1-table-title10", desc: "found-el2-table-text10" },
            { title: "found-el1-table-title11", desc: "found-el2-table-text11" },
            { title: "found-el1-table-title12", desc: "found-el2-table-text12" },
            { title: "found-el1-table-title13", desc: "found-el2-table-text13" }
        ]
    },
    "3": {
        title: "found-el3-title",
        topTexts: ["found-el3-text1", "found-el3-text2", "found-el3-text3"],
        links: [],
        tableData: [
            { title: "found-el1-table-title1", desc: "found-el3-table-text1" },
            {
                title: "found-el1-table-title3",
                desc: ["found-el3-table-text2-1"]
            },
            { title: "found-el1-table-title3", desc: "found-el3-table-text3" },
            { title: "found-el1-table-title4", desc: "found-el3-table-text4" },
            { title: "found-el1-table-title5", desc: "found-el3-table-text5" },
            { title: "found-el1-table-title6", desc: "found-el3-table-text6" },
            {
                title: "found-el1-table-title7",
                desc: ["found-el3-table-text7-1", "found-el3-table-text7-2"]
            },
            { title: "found-el1-table-title8", desc: "found-el3-table-text8" },
            { title: "found-el1-table-title9", desc: "found-el3-table-text9" },
            { title: "found-el1-table-title10", desc: "found-el3-table-text10" },
            { title: "found-el1-table-title11", desc: "found-el3-table-text11" },
            { title: "found-el1-table-title12", desc: "found-el3-table-text12" },
            { title: "found-el1-table-title13", desc: "found-el3-table-text13" }
        ]
    },
    "4": {
        title: "found-el4-title",
        topTexts: ["found-el4-text1", "found-el4-text2", "found-el4-text3"],
        links: [],
        tableData: [
            { title: "found-el1-table-title1", desc: "found-el4-table-text1" },
            {
                title: "found-el1-table-title2",
                desc: ["found-el4-table-text2-1", "found-el4-table-text2-2"]
            },
            { title: "found-el1-table-title3", desc: "found-el4-table-text3" },
            { title: "found-el1-table-title4", desc: "found-el4-table-text4" },
            { title: "found-el1-table-title5", desc: "found-el4-table-text5" },
            { title: "found-el1-table-title6", desc: "found-el4-table-text6" },
            {
                title: "found-el1-table-title7",
                desc: ["found-el4-table-text7-1", "found-el4-table-text7-2"]
            },
            { title: "found-el1-table-title8", desc: "found-el4-table-text8" },
            { title: "found-el1-table-title9", desc: "found-el4-table-text9" },
            { title: "found-el1-table-title10", desc: "found-el4-table-text10" },
            { title: "found-el1-table-title11", desc: "found-el4-table-text11" },
            { title: "found-el1-table-title12", desc: "found-el4-table-text12" },
            { title: "found-el1-table-title13", desc: "found-el4-table-text13" }
        ]
    }
};
