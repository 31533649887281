import React from "react";
import { useTranslation } from "react-i18next";
import "./Css/Publishing.css";

export interface IPublishingProps { }

const Publishing: React.FC<IPublishingProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="publishing-view">
            <div className="publishing-container">
                <div className="publishing-data-row">
                    <div className="name">Cégjegyzékben nyilvántartott adatok változásának közzététele</div>
                    <a
                        href="/Cegjegyzekben_nyilvantartott_adatok_valtozasanak_kozzetetele_20200921.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">XANGA Ventures Zrt 2019. évi beszámolója</div>
                    <a
                        href="/XANGA_Ventures_2019_evi_beszamolo.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Publishing;
