import { IPersonnel } from "../../CompanyDetails/Interface/IPersonnel.interface";

export const Management: IPersonnel[] = [
    {
        title: "Herdon István",
        description: "leadersDesc1"
    },
    {
        title: "Batai Henrietta",
        description: "leadersDesc2"
    },
    {
        title: "Nyitrai Zsolt",
        description: "leadersDesc3"
    },
    {
        title: "Tóthné Máthé Anikó",
        description: "leadersDesc4"
    },
    {
        title: "Herdon László",
        description: "leadersDesc5"
    },
    {
        title: "Kovács Zsolt",
        description: "leadersDesc6"
    },
    {
        title: "Kis Tamás",
        description: "leadersDesc7"
    }
];
