import React from "react";
import { IWithRouter, NavigationRouter } from "xa-navigation";
import { withRouter, Switch } from "react-router";
import { ApplicationAccess } from "access-factory";
import PageNotFound from "../E404/View/E404.view";
import CompanyDataView from "../CompanyDetails/View/CompanyData.view";
import FoundationView from "../Foundations/View/Foundation.view";
import Foundations from "../Foundations/View/Foundations.view";
import Connection from "../Connection/View/Connection.view";
import Complaints from "../Complaints/View/Complaints.view";
import Publishing from "../Publishing/View/Publishing.view";
import MainPage from "../MainPage/View/MainPage.view";
import Layout from "../Layout/View/Layout.view";
// import Posts from "../Posts/View/Posts.view";

const AppRoutes: React.FC<IWithRouter> = (props): React.ReactElement => {
    const routes: React.ReactNode = (
        <Switch>
            <NavigationRouter path="/cegadatok" component={CompanyDataView} exact={true} />
            <NavigationRouter path="/kezelt-alapok" component={Foundations} exact={true} />
            <NavigationRouter
                path="/kezelt-alapok/:foundation_id"
                component={FoundationView}
                exact={true}
            />
            {/* <NavigationRouter path="/posztok" component={Posts} exact={true} /> */}
            <NavigationRouter path="/kozzetetel" component={Publishing} exact={true} />
            <NavigationRouter path="/kapcsolat" component={Connection} exact={true} />
            <NavigationRouter path="/panaszkezeles" component={Complaints} exact={true} />
            <NavigationRouter path="/" component={MainPage} exact={true} />
            <NavigationRouter path="*" component={PageNotFound} exact={true} />
        </Switch>
    );
    return (
        <ApplicationAccess prevent_autologin={true} Spinner={null}>
            <Layout>{routes}</Layout>
        </ApplicationAccess>
    );
};

export default withRouter(AppRoutes);
