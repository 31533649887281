import React from "react";
import NavbarController from "../../Navigation/Controller/Navbar.controller";
import Logo from "../../../Assets/xanga_ventures_logo.png";
import "./Less/Layout.css";

export interface ILayoutProps {}

const Layout: React.FC<ILayoutProps> = (props): React.ReactElement => {
    return (
        <React.Fragment>
            <NavbarController />
            <div className="header-image">{props.children}</div>
            <div className="footer">
                <div className="top">XANGA Ventures {new Date().getFullYear()}</div>
                <div className="bottom">
                    <img src={Logo} alt="XANGA Ventures" className="logo-img" />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Layout;
